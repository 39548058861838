import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useApi, useSnackbar, useAuth } from 'contexts'

import { Stack, Button, Typography, Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import { ReactComponent as CoachOfferIllustration } from 'assets/images/illustrations/coach_offer.svg'

import { gql } from '@apollo/client'

const GET_CHECKOUT_LINK = gql`
  query getUser {
    user: getUser {
      id
      subscription {
        active
        createLink
        manageLink
      }
    }
  }
`

const IllustrationStyle = styled(CoachOfferIllustration)(({ theme }) => ({
  width: '100%',
  color: theme.palette.primary.main
}))

const Offer = () => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { useLazyQuery } = useApi()
  const { displaySnackbar } = useSnackbar()

  const [getCheckoutLink, { data: getCheckoutLinkData, loading }] = useLazyQuery(GET_CHECKOUT_LINK)

  useEffect(() => {
    if (getCheckoutLinkData) {
      if (getCheckoutLinkData?.user?.subscription && !getCheckoutLinkData?.user?.subscription?.active && getCheckoutLinkData?.user?.subscription?.createLink) {
        window.location.href = getCheckoutLinkData.user.subscription.createLink
      } else {
        displaySnackbar({ status: 'error', message: t('navbar.becomecoach.error') })
      }
    }
  }, [getCheckoutLinkData])

  return (
    <Stack
      spacing={3}
      sx={{ p: 5, width: 1, textAlign: 'center', display: 'block', maxWidth: 350 }}
    >
      <Box sx={{ display: 'flex', width: '100%' }}>
        <IllustrationStyle />
      </Box>

      <Typography gutterBottom variant="h4">
        {t('navbar.becomecoach.title')}
      </Typography>

      <Typography gutterBottom variant="body2" px={3}>
        {t('navbar.becomecoach.subtitle', { currency: user.inZoneEuro ? '€' : '$' })}
      </Typography>

      <Button variant="contained" onClick={() => getCheckoutLink()} disabled={loading}>
        {t('navbar.becomecoach.buttons.discover')}
      </Button>
    </Stack>
  )
}

export default Offer
