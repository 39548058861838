import numeral from 'numeral'
import { m, AnimatePresence } from 'framer-motion'

import { alpha } from '@mui/material/styles'
import { List, Stack, Button, IconButton, ListItemText, ListItem } from '@mui/material'
import { InsertDriveFile, Close } from '@mui/icons-material'

import { UploadMultiFileProps, CustomFile } from '../../upload.types'

import Image from 'components/image/Image'

function fData (number: string | number) {
  return numeral(number).format('0.0 b')
}

const getFileData = (file: CustomFile | string) => {
  if (typeof file === 'string') {
    return {
      key: file
    }
  }
  return {
    key: file.name,
    name: file.name,
    size: file.size,
    preview: file.preview
  }
}

const MultiFilePreview = ({
  showPreview = false,
  files,
  onRemove,
  onRemoveAll
}: UploadMultiFileProps) => {
  const hasFile = files.length > 0

  return (
    <>
      <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
        <AnimatePresence>
          {files.map((file) => {
            const { key, name, size, preview } = getFileData(file as CustomFile)

            if (showPreview) {
              return (
                <ListItem
                  key={key}
                  component={m.div}
                  initial={{ x: 120, opacity: 0 } }
                  animate={{ x: 0, opacity: 1, transition: { duration: 0.64, ease: [0.43, 0.13, 0.23, 0.96] } }}
                  exit={{ x: 120, opacity: 0, transition: { duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96] } }}
                  sx={{
                    p: 0,
                    m: 0.5,
                    width: 80,
                    height: 80,
                    borderRadius: 1.25,
                    overflow: 'hidden',
                    position: 'relative',
                    display: 'inline-flex',
                    border: (theme) => `solid 1px ${theme.palette.divider}`
                  }}
                >
                  <Image alt="preview" src={typeof file === 'string' ? file : preview} ratio="1/1" />
                  <IconButton
                    size="small"
                    onClick={() => onRemove(file)}
                    sx={{
                      top: 6,
                      p: '2px',
                      right: 6,
                      position: 'absolute',
                      color: 'common.white',
                      bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                      '&:hover': {
                        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48)
                      }
                    }}
                  >
                    <Close />
                  </IconButton>
                </ListItem>
              )
            }

            return (
              <ListItem
                key={key}
                component={m.div}
                initial={{ x: 120, opacity: 0 } }
                animate={{ x: 0, opacity: 1, transition: { duration: 0.64, ease: [0.43, 0.13, 0.23, 0.96] } }}
                exit={{ x: 120, opacity: 0, transition: { duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96] } }}
                sx={{
                  my: 1,
                  px: 2,
                  py: 0.75,
                  borderRadius: 0.75,
                  border: (theme) => `solid 1px ${theme.palette.divider}`
                }}
              >
                <InsertDriveFile sx={{ fontSize: 28, mr: 2, color: 'text.secondary' }} />

                <ListItemText
                  primary={typeof file === 'string' ? file : name}
                  secondary={typeof file === 'string' ? '' : fData(size || 0)}
                  primaryTypographyProps={{ variant: 'subtitle2' }}
                  secondaryTypographyProps={{ variant: 'caption' }}
                />

                <IconButton edge="end" size="small" onClick={() => onRemove(file)}>
                  <Close />
                </IconButton>
              </ListItem>
            )
          })}
        </AnimatePresence>
      </List>

      {hasFile && (
        <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
          <Button color="inherit" size="small" onClick={onRemoveAll}>
            Remove all
          </Button>
          <Button size="small" variant="contained">
            Upload files
          </Button>
        </Stack>
      )}
    </>
  )
}

export default MultiFilePreview
