import { useState, useEffect, ElementType } from 'react'

import { Toolbar, Head, Body, Pagination } from './components'

import { Table, TableContainer } from '@mui/material'

import { Scrollbar } from 'components'

import { applySortFilter, applySearchFilter } from 'utils'
import { ApolloError } from '@apollo/client'

import { ColumnProps } from './table.types'

interface RowAction {
  label: string
  icon: ElementType
  to?: (id: string) => string
  onClick?: (id: string) => void
  error?: boolean
}

interface MTableProps {
  loading?: boolean
  error?: ApolloError
  datas: any[]
  columns: ColumnProps[]
  rowClick?: (data: any) => void
  rowActions?: RowAction[]
  order?: 'asc' | 'desc'
  orderBy?: string
}

const MTable = ({ datas, columns, error, loading, rowClick, rowActions, order: defaultOrder = 'asc', orderBy: defaultOrderBy }: MTableProps) => {
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [order, setOrder] = useState<'asc' | 'desc'>(defaultOrder)
  const [orderBy, setOrderBy] = useState<string>(defaultOrderBy ?? (columns.filter((column: ColumnProps) => column.canSort)[0]?.id || ''))
  const [search, setSearch] = useState<string>('')
  const [filteredDatas, setFilteredDatas] = useState<any[]>([])
  const [slicedFilteredDatas, setSlicedFilteredDatas] = useState<any[]>([])
  const [activeSearch, setActiveSearch] = useState<boolean>(false)

  useEffect(() => {
    const canBeSearch: string[] = columns.filter((column: ColumnProps) => column.canSearch).map((column: ColumnProps) => column.id)
    let tmpDatas = datas
    if (canBeSearch.length) {
      setActiveSearch(true)
      tmpDatas = applySearchFilter(tmpDatas, canBeSearch, search)
    } else { setActiveSearch(false) }
    if (orderBy.length) {
      tmpDatas = applySortFilter(tmpDatas, order, orderBy)
    }
    setFilteredDatas(tmpDatas)
  }, [datas, columns, search, order, orderBy])

  useEffect(() => {
    setSlicedFilteredDatas(filteredDatas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
  }, [filteredDatas, page, rowsPerPage])

  return (<>
    {activeSearch && <Toolbar search={search} setSearch={setSearch} />}
    <Scrollbar>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <Head columns={columns} order={order} orderBy={orderBy} setOrder={setOrder} setOrderBy={setOrderBy} actions={Boolean(rowActions?.length)} />
          <Body loading={loading} error={Boolean(error)} datas={slicedFilteredDatas} columns={columns} rowClick={rowClick} rowActions={rowActions} />
        </Table>
      </TableContainer>
    </Scrollbar>
    <Pagination count={filteredDatas.length} rowsPerPage={rowsPerPage} page={page} setPage={setPage} setRowsPerPage={setRowsPerPage} />
  </>)
}

export default MTable
