import { useFormContext, Controller } from 'react-hook-form'

import { TextField } from '@mui/material'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'

import { ControlDatePickerProps } from './controlDatePicker.types'

import { useAuth } from 'contexts'

const dateFormatConverter: any = {
  DDMMYY: 'dd/MM/yyyy',
  MMDDYY: 'MM/dd/yyyy',
  YYMMDD: 'yyyy/MM/dd'
}

const ControlDatePicker = ({ name, rules, ...other }: ControlDatePickerProps) => {
  const { user } = useAuth()
  const { control } = useFormContext()

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={null}
        render={({ field, fieldState: { error } }) => (
          <DatePicker
            {...field}
            inputFormat={dateFormatConverter[user.preferences.units.date] || 'MM/dd/yyyy'}
            renderInput={(params: any) => <TextField fullWidth error={!!error} helperText={error?.message} {...params} {...other} />}
          />
        )}
      />
    </LocalizationProvider>
  )
}

export default ControlDatePicker
