import Avatar, { Props as AvatarProps } from '../avatar/Avatar'

const PRIMARY_NAME = ['A', 'N', 'H', 'L', 'Q', '9', '8']
const INFO_NAME = ['F', 'G', 'T', 'I', 'J', '1', '2', '3']
const SUCCESS_NAME = ['K', 'D', 'Y', 'B', 'O', '4', '5']
const WARNING_NAME = ['P', 'E', 'R', 'S', 'C', 'U', '6', '7']
const ERROR_NAME = ['V', 'W', 'X', 'M', 'Z']

function getFirstCharacter (name: string) {
  return name && name.charAt(0).toUpperCase()
}

function getAvatarColor (name: string) {
  if (PRIMARY_NAME.includes(getFirstCharacter(name))) return 'warning'// 'primary'
  if (INFO_NAME.includes(getFirstCharacter(name))) return 'warning'// 'info'
  if (SUCCESS_NAME.includes(getFirstCharacter(name))) return 'warning'// 'success'
  if (WARNING_NAME.includes(getFirstCharacter(name))) return 'warning'// 'warning'
  if (ERROR_NAME.includes(getFirstCharacter(name))) return 'warning'// 'error'
  return 'default'
}

function createAvatar (name?: string) {
  return {
    name: getFirstCharacter(name || 'F'),
    color: getAvatarColor(name || 'F')
  } as const
}

interface MyAvatarCustomProps {
  image?: string
  name?: string
}

type MyAvatarProps = AvatarProps & MyAvatarCustomProps

const MyAvatar = ({ image, name, ...other }: MyAvatarProps) => {
  return (
    <Avatar
      src={image}
      alt={name}
      color={image ? 'default' : createAvatar(name).color}
      {...other}
    >
      {createAvatar(name).name}
    </Avatar>
  )
}

export default MyAvatar
