import { AuthRoles } from './authRoles.types'

const authRoles: AuthRoles = {
  ADMIN: ['ADMIN'],
  COACH: ['COACH'],
  CHALLENGER: ['CHALLENGER', 'COACH'],
  ALL: ['CHALLENGER', 'COACH', 'ADMIN'],
  GUEST: ['']
}

export default authRoles
