import { Theme } from '@mui/material/styles'

export default function Typography (theme: Theme) {
  return {
    MuiTypography: {
      styleOverrides: {
        h3: {
          ':first-letter': {
            textTransform: 'capitalize' as 'capitalize'
          }
        },
        h4: {
          ':first-letter': {
            textTransform: 'capitalize' as 'capitalize'
          }
        },
        h5: {
          ':first-letter': {
            textTransform: 'capitalize' as 'capitalize'
          }
        },
        h6: {
          ':first-letter': {
            textTransform: 'capitalize' as 'capitalize'
          }
        },
        subtitle1: {
          ':first-letter': {
            textTransform: 'capitalize' as 'capitalize'
          }
        },
        subtitle2: {
          ':first-letter': {
            textTransform: 'capitalize' as 'capitalize'
          }
        },
        body2: {
          ':first-letter': {
            textTransform: 'capitalize' as 'capitalize'
          }
        },
        caption: {
          ':first-letter': {
            textTransform: 'capitalize' as 'capitalize'
          }
        },
        paragraph: {
          marginBottom: theme.spacing(2)
        },
        gutterBottom: {
          marginBottom: theme.spacing(1)
        }
      }
    }
  }
}
