import { alpha, Theme } from '@mui/material/styles'

export default function Button (theme: Theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          lineHeight: 2,
          '&:hover': {
            boxShadow: 'none' as 'none'
          }
        },
        sizeLarge: {
          height: 48
        },
        // contained
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: `0 8px 16px 0 ${alpha(theme.palette.grey[500], 0.16)}`,
          '&:hover': {
            backgroundColor: theme.palette.grey[400]
          }
        },
        containedPrimary: {
          boxShadow: `0 8px 16px 0 ${alpha(theme.palette.primary.main, 0.24)}`
        },
        containedSecondary: {
          boxShadow: `0 8px 16px 0 ${alpha(theme.palette.secondary.main, 0.24)}`
        },
        containedInfo: {
          boxShadow: `0 8px 16px 0 ${alpha(theme.palette.info.main, 0.24)}`
        },
        containedSuccess: {
          boxShadow: `0 8px 16px 0 ${alpha(theme.palette.success.main, 0.24)}`
        },
        containedWarning: {
          boxShadow: `0 8px 16px 0 ${alpha(theme.palette.warning.main, 0.24)}`
        },
        containedError: {
          boxShadow: `0 8px 16px 0 ${alpha(theme.palette.error.main, 0.24)}`
        },
        // outlined
        outlinedInherit: {
          border: `1px solid ${alpha('#919EAB', 0.32)}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        }
      }
    }
  }
}
