import { createContext, useContext, useMemo } from 'react'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/analytics'
import 'firebase/compat/storage'

import { InitialValues, FirebaseProviderProps } from './firebase.types'

const initialValues: InitialValues = {}

const FirebaseContext = createContext(initialValues)

const initApp = () => {
  const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE
    // appId: process.env.REACT_APP_ID
  }

  const firebaseApp = firebase.initializeApp(config)
  firebase.auth().useDeviceLanguage()

  if (process.env.REACT_APP_NODE_ENV === 'local') {
    firebaseApp.storage(process.env.REACT_APP_FIREBASE_STORAGE).useEmulator('localhost', 9199)
    firebase.auth().useEmulator('http://localhost:9099')
  }

  return {
    firebase,
    firebaseApp
  }
}

const FirebaseProvider = ({ children }: FirebaseProviderProps) => {
  const app = useMemo<any>(initApp, [])

  return (
    <FirebaseContext.Provider value={app}>
      {children}
    </FirebaseContext.Provider>
  )
}

const useFirebase = () => {
  return useContext(FirebaseContext)
}

export { FirebaseProvider, useFirebase }
