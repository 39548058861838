import { AppConfig } from './app.types'

const appConfig: AppConfig = {
  whiteBranding: false,
  header: {
    searchbar: false,
    notifications: false
  },
  navbar: {
    horizontal: false,
    canBeCollapse: false,
    user: true,
    bottomInfo: true
  },
  stretch: true
}

export default appConfig
