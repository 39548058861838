import { useFormContext, Controller } from 'react-hook-form'

import { TextField } from '@mui/material'

import { ControlSelectProps } from './controlSelect.types'

export default function ControlSelect ({ name, rules, children, multiple, renderValue, defaultValue, ...other }: ControlSelectProps) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue || (multiple ? [] : '')}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          select
          fullWidth
          SelectProps={{ native: false, multiple, renderValue }}
          error={!!error}
          helperText={error?.message}
          {...other}
        >
          {children}
        </TextField>
      )}
    />
  )
}
