import { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { useFormContext, Controller } from 'react-hook-form'

import { Box, FormHelperText, CircularProgress } from '@mui/material'

import { ControlPhoneNumberProps } from './controlPhoneNumber.types'
import { styled } from '@mui/material/styles'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

import { getCountry } from 'utils/geolocation'

import { useAuth } from 'contexts'

const MPhoneInput = styled(PhoneInput)(({ theme }) => ({
  '.form-control': {
    '&:focus': {
      borderColor: `${theme.palette.primary.main} !important`,
      boxShadow: `0px 0px 0px 1px ${theme.palette.primary.main} !important`
    }
  }/*,
  '.special-label': {
    color: `${theme.palette.primary.main} !important`
  } */
}))

const ControlPhoneNumber = ({ name, rules, ...other }: ControlPhoneNumberProps) => {
  const { control } = useFormContext()
  const { t } = useTranslation()
  const { user } = useAuth()

  const [defaultCountry, setDefaultCountry] = useState<string>('us')
  const [loadingCountry, setLoadingCountry] = useState<boolean>(true)

  useEffect(() => {
    const userCountryCode = user?.countryCode?.toLowerCase() || sessionStorage.getItem('userCountryCode')
    if (userCountryCode) {
      setDefaultCountry(userCountryCode)
      setLoadingCountry(false)
      return
    }

    getCountry((countryCode: string) => {
      if (countryCode) {
        setDefaultCountry(countryCode.toLowerCase())
        sessionStorage.setItem('userCountryCode', countryCode.toLowerCase())
      }
      setLoadingCountry(false)
    })
  }, [])

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        return loadingCountry
          ? <CircularProgress />
          : (<Box>
            <MPhoneInput
              {...field}
              country={defaultCountry}
              enableSearch={true}
              inputProps={{
                name: t('global.inputs.phone'),
                required: true,
                autoFocus: true
              }}
              inputStyle={{
                width: '100%'
              }}
              {...other}
            />
            {error && (
              <FormHelperText error sx={{ px: 2, textAlign: 'left' }}>
                {error?.message || t('global.inputs.phone.invalid')}
              </FormHelperText>
            )}
          </Box>)
      }}
    />
  )
}

export default ControlPhoneNumber
