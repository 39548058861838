import { useCallback } from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import { FormHelperText } from '@mui/material'

import {
  UploadAvatar,
  UploadMultiFile,
  UploadSingleFile,
  UploadProps,
  UploadMultiFileProps
} from './upload'

interface Props extends Omit<UploadProps, 'file'> {
  name: string
}

export function ControlUploadAvatar ({ name, ...other }: Props) {
  const { control, setValue } = useFormContext()

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0]

      if (file) {
        setValue(
          name,
          Object.assign(file, {
            preview: (window.URL ? window.URL : window.webkitURL).createObjectURL(file)
          })
        )
      }
    },
    [setValue]
  )

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value

        return (
          <div>
            <UploadAvatar error={checkError} onDrop={handleDrop} {...other} file={field.value} />
            {checkError && (
              <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                {error.message}
              </FormHelperText>
            )}
          </div>
        )
      }}
    />
  )
}

export function ControlUploadSingleFile ({ name, ...other }: Props) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value

        return (
          <UploadSingleFile
            accept="image/*"
            file={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        )
      }}
    />
  )
}

interface ControlUploadMultiFileProps extends Omit<UploadMultiFileProps, 'files'> {
  name: string;
}

export function ControlUploadMultiFile ({ name, ...other }: ControlUploadMultiFileProps) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && field.value?.length === 0

        return (
          <UploadMultiFile
            accept="image/*"
            files={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error?.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        )
      }}
    />
  )
}
