import { Theme } from '@mui/material/styles'

export default function Select (theme: Theme) {
  return {
    MuiMenu: {
      styleOverrides: {
        root: {
          maxHeight: 300
        }
      }
    }
  }
}
