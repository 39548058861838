import { createContext, useContext, useState, MouseEventHandler, MouseEvent } from 'react'

import { Dialog, DialogTitle, DialogActions, Button } from '@mui/material'

import { InitialValues, ConfirmDialogProviderProps, DisplayConfirmDialogProps } from './confirmDialog.types'

import { t } from 'i18next'

const initialValues: InitialValues = {
  displayConfirmDialog: () => {},
  forceConfirmDialogClosing: () => {}
}

const ConfirmDialog = createContext(initialValues)

const ConfirmDialogProvider = ({ children }: ConfirmDialogProviderProps) => {
  const [open, setOpen] = useState<boolean>(false)

  const handleClose = () => { setOpen(false) }

  const [text, setText] = useState<string>(t('global.confirmDialog.text'))
  const [agreeText, setAgreeText] = useState<string>(t('global.confirmDialog.agree'))
  const [declineText, setDeclineText] = useState<string>(t('global.confirmDialog.decline'))
  const [agree, setAgree] = useState<MouseEventHandler>()
  const [decline, setDecline] = useState<MouseEventHandler>()

  const displayConfirmDialog = ({ text, agreeText, declineText, agree, decline }: DisplayConfirmDialogProps) => {
    if (text) setText(text)
    if (agreeText) { setAgreeText(agreeText) }
    if (declineText) { setDeclineText(declineText) }
    setAgree(() => (event: MouseEvent) => {
      agree(event)
      handleClose()
    })
    setDecline(() => (event: MouseEvent) => {
      if (decline) {
        decline(event)
      }
      handleClose()
    })
    setOpen(true)
  }

  return (
    <ConfirmDialog.Provider value={{ displayConfirmDialog, forceConfirmDialogClosing: handleClose }}>
      {children}
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {text}
        </DialogTitle>
        <DialogActions>
          <Button onClick={decline} color="error">
            {declineText}
          </Button>
          <Button onClick={agree} autoFocus>
            {agreeText}
          </Button>
        </DialogActions>
      </Dialog>
    </ConfirmDialog.Provider>
  )
}

const useConfirmDialog = () => {
  return useContext(ConfirmDialog)
}

export { ConfirmDialogProvider, useConfirmDialog }
