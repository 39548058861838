import { Theme } from '@mui/material/styles'

export default function TextField (theme: Theme) {
  return {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          ':first-letter': {
            textTransform: 'capitalize' as 'capitalize'
          }
        }
      }
    }
  }
}
