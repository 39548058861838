import { useMemo } from 'react'
import merge from 'lodash/merge'
import ReactApexChart from 'react-apexcharts'

import { useTheme } from '@mui/material/styles'
import { Card, Typography, Stack, Divider } from '@mui/material'

import { getDevice, fNumber } from 'utils'

import BaseOptionChart from '../charts.data'

const CHART_SIZE = { width: 106, height: 106 }

interface RadialBarCompareLegendsProps {
  value: string
  rest: string
}

interface RadialBarCompareProps {
  value: number
  total: number
  legends: RadialBarCompareLegendsProps
}

const RadialBarCompare = ({ value, total, legends }:RadialBarCompareProps) => {
  const theme = useTheme()

  const isDesktop = getDevice() === 'desktop'

  const chartOptions = useMemo(() => merge(
    BaseOptionChart(),
    {
      chart: { sparkline: { enabled: true } },
      grid: {
        padding: {
          top: -9,
          bottom: -9
        }
      },
      legend: { show: false },
      plotOptions: {
        radialBar: {
          hollow: { size: '64%' },
          track: { margin: 0 },
          dataLabels: {
            name: { show: false },
            value: {
              offsetY: 6,
              fontSize: theme.typography.subtitle2.fontSize
            }
          }
        }
      }
    }
  ), [])

  const chartOptionsSecondary = {
    ...chartOptions,
    colors: [theme.palette.secondary.main]
  }

  return (
    <Card>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        divider={
          <Divider
            orientation={isDesktop ? 'vertical' : 'horizontal'}
            flexItem
            sx={{ borderStyle: 'dashed' }}
          />
        }
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={3}
          sx={{ width: 1, py: 5 }}
        >
          <ReactApexChart
            type="radialBar"
            series={[Math.round(value * 100 / total)]}
            options={chartOptions}
            {...CHART_SIZE}
          />
          <div>
            <Typography variant="h4" sx={{ mb: 0.5 }}>
              {fNumber(value)}
            </Typography>
            <Typography variant="body2" sx={{ opacity: 0.72 }}>
              {legends.value}
            </Typography>
          </div>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={3}
          sx={{ width: 1, py: 5 }}
        >
          <ReactApexChart
            type="radialBar"
            series={[Math.round((total - value) * 100 / total)]}
            options={chartOptionsSecondary}
            {...CHART_SIZE}
          />
          <div>
            <Typography variant="h4" sx={{ mb: 0.5 }}>
              {fNumber(total - value)}
            </Typography>
            <Typography variant="body2" sx={{ opacity: 0.72 }}>
              {legends.rest}
            </Typography>
          </div>
        </Stack>
      </Stack>
    </Card>
  )
}

export default RadialBarCompare
