import { ContextsProviderProps } from './contextsProvider.types'

import { AuthProvider, BrandProvider, ApiProvider, SnackbarProvider, FirebaseProvider, ConfirmDialogProvider } from 'contexts/providers'

const ContextsProvider = ({ children } : ContextsProviderProps) => {
  return (
    <ApiProvider>
      <BrandProvider>
        <FirebaseProvider>
          <AuthProvider>
            <SnackbarProvider>
              <ConfirmDialogProvider>
                {children}
              </ConfirmDialogProvider>
            </SnackbarProvider>
          </AuthProvider>
        </FirebaseProvider>
      </BrandProvider>
    </ApiProvider>
  )
}

export default ContextsProvider
