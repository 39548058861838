import merge from 'lodash/merge'
import { Card, CardHeader, Box } from '@mui/material'

import ReactApexChart from 'react-apexcharts'

import BaseOptionChart from '../charts.data'

interface LineDataProps {
  name: string
  type: string
  data: number[]
}

interface LineProps {
  title: string
  subtitle?: string
  legends: string[]
  datas: LineDataProps[]
  datetime?: boolean
}

const Line = ({ title, subtitle, legends, datas, datetime = true }: LineProps) => {
  const chartOptions = merge(
    BaseOptionChart(),
    {
      stroke: { width: [3, 3, 3, 3] },
      plotOptions: { bar: { columnWidth: '14%' } },
      fill: { type: ['solid', 'gradient', 'solid'] },
      labels: legends,
      xaxis: datetime ? { type: 'datetime' as 'datetime' } : {},
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: (y: number) => {
            if (typeof y !== 'undefined') {
              return `${y.toFixed(0)}`
            }
            return y
          }
        }
      }
    }
  )

  return (
    <Card>
      <CardHeader title={title} subheader={subtitle} />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={datas} options={chartOptions} height={364} />
      </Box>
    </Card>
  )
}

export default Line
