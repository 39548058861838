import { ReactElement } from 'react'

import { alpha, styled } from '@mui/material/styles'
import { Card, Typography } from '@mui/material'

import { ColorSchema, fShortenNumber } from 'utils'

const RootStyle = styled(Card)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(5, 0)
}))

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3)
}))

type DataIconBgProps = {
  title?: string
  total: number | string
  icon: ReactElement
  bgColor?: ColorSchema
  light?: boolean
  totalUnit?: string
  iconBg?: boolean
}

const DataIconBg = ({ title = '', total, icon, bgColor, light, totalUnit, iconBg = true }: DataIconBgProps) => {
  return (
    <RootStyle
      sx={bgColor
        ? {
            color: (theme) => (theme.palette[bgColor] as any).darker,
            bgcolor: (theme) => (theme.palette[bgColor] as any).lighter
          }
        : {}}
    >
      <IconWrapperStyle
        sx={{
          color: (theme: any) => light ? theme.palette.primary.contrastText : theme.palette.text.primary,
          backgroundImage: (theme: any) =>
            iconBg && bgColor
              ? `linear-gradient(135deg, ${alpha((theme.palette[bgColor] as any).darker, 0)} 0%, ${alpha(
              (theme.palette[bgColor] as any).darker,
              0.24
            )} 100%)`
              : '',
          width: (theme: any) => theme.spacing(title.length ? 8 : 10),
          height: (theme: any) => theme.spacing(title.length ? 8 : 10)
        }}
      >
        {icon}
      </IconWrapperStyle>
      <Typography variant="h3" sx={{ color: (theme) => light ? theme.palette.primary.contrastText : theme.palette.text.primary }}>{fShortenNumber(total, Boolean(bgColor))} {totalUnit}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72, color: (theme) => light ? theme.palette.primary.contrastText : theme.palette.text.primary }}>
        {title}
      </Typography>
    </RootStyle>
  )
}

export default DataIconBg
