import { useFormContext, Controller } from 'react-hook-form'

import { TextField } from '@mui/material'

import { formatString } from 'utils'

import { ControlTextFieldProps } from './controlTextField.types'

const ControlTextField = ({ name, rules, placeholder = '', ...other }: ControlTextFieldProps) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <TextField {...field} fullWidth error={!!error} helperText={error?.message} placeholder={formatString(placeholder, 'firstLetter')} {...other} />
      )}
    />
  )
}

export default ControlTextField
