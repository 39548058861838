import { useState, MouseEvent, ElementType } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import { MenuItem, IconButton, TableBody, TableRow, TableCell, CircularProgress } from '@mui/material'
import { MoreVert, Dangerous } from '@mui/icons-material'

import { MenuPopover } from 'components'

import { ColumnProps } from '../../table.types'

import { arrayValueFromPath } from 'utils'

interface RowAction {
  label: string
  icon: ElementType
  to?: (id: string) => string
  onClick?: (id: string) => void
  error?: boolean
}

interface BodyLoadingProps {
  columnCount: number
}

const BodyLoading = ({ columnCount }: BodyLoadingProps) => {
  return (
    <TableBody>
      <TableRow>
        <TableCell sx={{ textAlign: 'center' }} colSpan={columnCount}>
          <CircularProgress />
        </TableCell>
      </TableRow>
    </TableBody>
  )
}

interface BodyErrorProps {
  columnCount: number
}

const BodyError = ({ columnCount }: BodyErrorProps) => {
  return (
    <TableBody>
      <TableRow>
        <TableCell sx={{ textAlign: 'center' }} colSpan={columnCount}>
          <Dangerous color='error' /><br/>
          An error occured during the data loading process.<br/>
          Please refresh the page.
        </TableCell>
      </TableRow>
    </TableBody>
  )
}

interface BodyEmptyProps {
  columnCount: number
}

const BodyEmpty = ({ columnCount }: BodyEmptyProps) => {
  const { t } = useTranslation()

  return (
    <TableBody>
      <TableRow>
        <TableCell sx={{ textAlign: 'center' }} colSpan={columnCount}>
          {t('global.nomatch')}
        </TableCell>
      </TableRow>
    </TableBody>
  )
}

interface CellProps {
  data: any
  column: ColumnProps
  onClick: (data: any) => void
}

const Cell = ({ data, column, onClick }: CellProps) => {
  const value = arrayValueFromPath(data, column.id)

  return (
    <TableCell align={column.align || 'left'} sx={{ minWidth: 70 }} onClick={onClick}>
      {column.display ? column.display(value || 0, data) : value || 0}
    </TableCell>
  )
}

interface RowProps {
  data: any
  columns: ColumnProps[]
  rowClick?: (data: any) => void
  rowActions?: RowAction[]
}

const Row = ({ data, columns, rowClick, rowActions }: RowProps) => {
  const [open, setOpen] = useState<HTMLElement | null>(null)
  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  return (
    <TableRow
      hover
      tabIndex={-1}
      role="checkbox"
      sx={rowClick ? { cursor: 'pointer' } : {}}
    >
      {columns.map((column: ColumnProps) => <Cell key={`column-${column.id}-${data.id}`} data={data} column={column} onClick={rowClick ? () => rowClick(data) : () => {}} />)}
      {rowActions?.length && <TableCell align='right'>
        <IconButton onClick={handleOpen}>
          <MoreVert width={20} height={20} />
        </IconButton>

        <MenuPopover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          arrow="right-top"
          sx={{
            mt: -1,
            width: 160,
            '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 }
          }}
        >
          {rowActions.map((rowAction: RowAction) => <MenuItem
            key={`rowAction-${rowAction.label}-${data.id}`}
            component={rowAction.to ? RouterLink : MenuItem}
            to={rowAction.to ? rowAction.to(data.id) : ''}
            onClick={() => rowAction.onClick ? rowAction.onClick(data.id) : {}}
            sx={rowAction.error ? { color: 'error.main' } : {}}
          >
            {<rowAction.icon width={20} height={20} sx={{ mr: 2 }} />}
            {rowAction.label}
          </MenuItem>)}
        </MenuPopover>
      </TableCell>}
    </TableRow>
  )
}

interface BodyProps {
  datas: any[]
  columns: ColumnProps[]
  loading?: boolean
  error: boolean
  rowClick?: (data: any) => void
  rowActions?: RowAction[]
}

const Body = ({ datas, columns, loading, error, rowClick, rowActions }: BodyProps) => {
  if (loading) return <BodyLoading columnCount={columns.length} />
  if (error) return <BodyError columnCount={columns.length} />
  if (!datas.length) return <BodyEmpty columnCount={columns.length} />

  return (
    <TableBody>
      {datas.map((data: any) => <Row key={`row-${data.id}`} columns={columns} data={data} rowClick={rowClick} rowActions={rowActions} />)}
    </TableBody>
  )
}

export default Body
