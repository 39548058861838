import { Brand } from './brand.types'

import logoSquare from 'assets/images/logos/logo-square.png'
import logoLong from 'assets/images/logos/logo-long.png'

import backgroundAuth from 'assets/images/backgrounds/auth.webp'

const brand: Brand = {
  name: 'HerbaCrm',
  logos: {
    square: logoSquare,
    long: logoLong
  },
  colors: {
    primary: '#79be21',
    secondary: '#8641DE',
    textColor: '#f2f2f2'
  },
  backgrounds: {
    auth: backgroundAuth
  }
}

export default brand
