import axios from 'axios'

const countryEuroZone = ['AT', 'BE', 'CY', 'EE', 'FI', 'FR', 'DE', 'GR', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PT', 'SK', 'SI', 'ES']

const getCountryAsync = async () => {
  const res = await axios.get('https://ipinfo.io/json', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_IPINFO_TOKEN!}`
    }
  })
  return res?.data?.country ?? 'US'
}

const getCountry = (callback: (countryCode: string) => void) => {
  axios.get('https://ipinfo.io/json', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_IPINFO_TOKEN!}`
    }
  }).then((res) => {
    const countryCode: string = res?.data?.country ?? 'US'
    callback(countryCode)
  })
}

interface CountryInEuroZoneProps {
  countryCode?: string
}

const countryInEuroZone = async ({ countryCode }: CountryInEuroZoneProps) => {
  const countryCodeTmp = countryCode || await getCountryAsync()

  return countryEuroZone.includes(countryCodeTmp)
}

export { getCountryAsync, getCountry, countryInEuroZone, countryEuroZone }
