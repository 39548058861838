import { useDropzone } from 'react-dropzone'

import { styled, alpha } from '@mui/material/styles'
import { Box } from '@mui/material'

import { UploadMultiFileProps } from '../upload.types'

import { RejectionFiles, MultiFilePreview, BlockContent } from '../components'

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'rgb(244, 246, 248)',
  border: `1px dashed ${alpha('#919EAB', 0.32)}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' }
}))

const UploadMultiFile = ({
  error,
  showPreview = false,
  files,
  onRemove,
  onRemoveAll,
  helperText,
  sx,
  ...other
}: UploadMultiFileProps) => {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    ...other
  })

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter'
          })
        }}
      >
        <input {...getInputProps()} />

        <BlockContent />
      </DropZoneStyle>

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      <MultiFilePreview
        files={files}
        showPreview={showPreview}
        onRemove={onRemove}
        onRemoveAll={onRemoveAll}
      />

      {helperText && helperText}
    </Box>
  )
}

export default UploadMultiFile
