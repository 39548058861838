import { useMemo } from 'react'
import merge from 'lodash/merge'

import { styled, useTheme } from '@mui/material/styles'
import { Card, Typography, Stack } from '@mui/material'
import { TrendingUp as TrendingUpIcon, TrendingDown as TrendingDownIcon } from '@mui/icons-material'

import { fCurrency, fPercent } from 'utils'

import ReactApexChart from 'react-apexcharts'

import BaseOptionChart from '../charts.data'

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  padding: theme.spacing(3)
}))

const IconWrapperStyle = styled('div')(({ theme }) => ({
  width: 48,
  height: 48,
  display: 'flex',
  borderRadius: '50%',
  position: 'absolute',
  alignItems: 'center',
  top: theme.spacing(3),
  right: theme.spacing(3),
  justifyContent: 'center'
}))

type LineEvolveBgProps = {
  title: string
  value: number
  datas: number[]
  evolveLegend?: string
  simpleIcon?: boolean
  needToGoDown?: boolean
}

const LineEvolveBg = ({ title, datas, value, evolveLegend, simpleIcon, needToGoDown }: LineEvolveBgProps) => {
  const theme = useTheme()

  const percent = useMemo(() => ((100 - datas[datas.length - 1] * 100 / datas[datas.length - 2]) * -1), [])

  const chartOptions = merge(BaseOptionChart(), {
    colors: [(percent >= 0 && !needToGoDown) || (percent < 0 && needToGoDown) ? (theme.palette.success as any).dark : (theme.palette.error as any).dark],
    chart: { sparkline: { enabled: true } },
    xaxis: { labels: { show: false } },
    yaxis: { labels: { show: false } },
    stroke: { width: 4 },
    legend: { show: false },
    grid: { show: false },
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName: string) => fCurrency(seriesName),
        title: {
          formatter: () => ''
        }
      }
    },
    fill: { gradient: { opacityFrom: 0, opacityTo: 0 } }
  })

  return (
    <RootStyle sx={{
      color: (theme) => (percent >= 0 && !needToGoDown) || (percent < 0 && needToGoDown) ? (theme.palette.success as any).darker : (theme.palette.error as any).darker,
      backgroundColor: (theme) => (percent >= 0 && !needToGoDown) || (percent < 0 && needToGoDown) ? (theme.palette.success as any).lighter : (theme.palette.error as any).lighter
    }}>
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 3 }}>
        <div>
          <Typography sx={{ mb: 2, typography: 'subtitle2' }}>{title}</Typography>
          <Typography sx={{ typography: 'h3' }}>{fCurrency(value)}</Typography>
          {simpleIcon && <Stack direction="row" alignItems="center" flexWrap="wrap">
            {percent < 0 ? <TrendingDownIcon fontSize='small' /> : <TrendingUpIcon fontSize='small' />}
            <Typography variant="subtitle2" component="span" sx={{ ml: 0.5 }}>
              {fPercent(percent)}
            </Typography>
            {evolveLegend && <Typography variant="body2" component="span" sx={{ opacity: 0.72 }}>
                {evolveLegend}
            </Typography>}
          </Stack>}
        </div>

        {!simpleIcon
          ? <div>
              <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ mb: 0.6 }}>
                {percent < 0 ? <TrendingDownIcon fontSize='small' /> : <TrendingUpIcon fontSize='small' />}
                <Typography variant="subtitle2" component="span" sx={{ ml: 0.5 }}>
                  {fPercent(percent)}
                </Typography>
              </Stack>
              {evolveLegend && <Typography variant="body2" component="span" sx={{ opacity: 0.72 }}>
                {evolveLegend}
              </Typography>}
            </div>
          : <IconWrapperStyle
              sx={{
                color: (theme) => (percent >= 0 && !needToGoDown) || (percent < 0 && needToGoDown) ? (theme.palette.success as any).light : (theme.palette.error as any).light,
                bgcolor: (theme) => (percent >= 0 && !needToGoDown) || (percent < 0 && needToGoDown) ? (theme.palette.success as any).dark : (theme.palette.error as any).dark
              }}
            >
              {percent < 0 ? <TrendingDownIcon fontSize='small' /> : <TrendingUpIcon fontSize='small' />}
            </IconWrapperStyle>}
      </Stack>

      <ReactApexChart type="area" series={[{ data: datas }]} options={chartOptions} height={132} />
    </RootStyle>
  )
}

export default LineEvolveBg
