import { Link as RouterLink } from 'react-router-dom'

import { Box, Button, Typography } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'

interface NoDataProps {
  message: string
  buttonMessage?: string
  buttonLink?: string
  Illustration: any
}

const NoData = ({ message, buttonMessage, buttonLink, Illustration }: NoDataProps) => {
  return <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 4,
    height: 800
  }}>
    <Typography variant="h4" gutterBottom align='center'>
     {message}
    </Typography>
    {buttonMessage && buttonLink
      ? <Button
      sx={{ mb: 2 }}
      variant="contained"
      component={RouterLink}
      to={buttonLink}
      startIcon={<AddIcon />}
    >
      {buttonMessage}
    </Button>
      : ''}
    <Illustration style={{ maxWidth: '80%' }} height={400} />
  </Box>
}

export default NoData
