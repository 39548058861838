import { useFormContext, Controller, RegisterOptions } from 'react-hook-form'

import { Box, ToggleButtonGroup, ToggleButtonGroupProps, ToggleButton, FormHelperText } from '@mui/material'

interface CustomToggleButtonProps {
  value: string
  label: string
}
interface CustomControlToggleGroupProps {
  name: string
  choices: CustomToggleButtonProps[]
  emptyAllow?: boolean
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
}

type ControlToggleGroupProps = CustomControlToggleGroupProps & ToggleButtonGroupProps

const ControlToggleGroup = ({ name, choices, emptyAllow, rules, ...other }: ControlToggleGroupProps) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (<Box>
        <ToggleButtonGroup
          {...field}
          fullWidth
          {...other}
        >
          {choices.map((choice: CustomToggleButtonProps) => <ToggleButton key={choice.value} value={choice.value}>{choice.label}</ToggleButton>)}
        </ToggleButtonGroup>
        {error && (
          <FormHelperText error sx={{ px: 2, textAlign: 'left' }}>
            {error?.message || 'Value required'}
          </FormHelperText>
        )}
      </Box>)}
    />
  )
}

export default ControlToggleGroup
