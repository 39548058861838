import { Helmet } from 'react-helmet-async'

import { Container } from '@mui/material'
import { appConfig } from 'configs'
import { useBrand } from 'contexts'

import { formatString } from 'utils'

import { PageProps } from './page.types'

const Page = ({ children, title = '', meta }: PageProps) => {
  const { brand } = useBrand()

  return (
    <>
      <Helmet>
        <title>{`${brand?.name} | ${formatString(title, 'firstLetter')}`}</title>
        {meta}
      </Helmet>
      <Container maxWidth={appConfig.stretch ? false : 'lg'} >
        {children}
      </Container>
    </>
  )
}

export default Page
