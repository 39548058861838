import { ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { Box, Typography, Link, IconButton } from '@mui/material'
import { ArrowBackIosNew } from '@mui/icons-material'

import Breadcrumbs, { Props as BreadcrumbsProps } from '../breadcrumbs/Breadcrumbs'

interface Props extends BreadcrumbsProps {
  action?: ReactNode
  backTo?: string
  heading: string
  moreLink?: string | string[]
}

export default function HeaderBreadcrumbs ({
  links,
  action,
  backTo,
  heading,
  moreLink = '' || [],
  sx,
  ...other
}: Props) {
  return (
    <Box sx={{ mb: 5, ...sx }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', height: '40px' }}>
          {backTo &&
            <IconButton
              color='primary'
              component={RouterLink}
              to={backTo}
            >
              <ArrowBackIosNew />
            </IconButton>
          }
          <Typography variant="h4" gutterBottom sx={{ mb: 0 }}>
            {heading}
          </Typography>
          <Breadcrumbs links={links} {...other} />
        </Box>

        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
      </Box>

      <Box sx={{ mt: 2 }}>
        {typeof moreLink === 'string'
          ? (
          <Link href={moreLink} target="_blank" rel="noopener" variant="body2">
            {moreLink}
          </Link>
            )
          : (
              moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              rel="noopener"
              sx={{ display: 'table' }}
            >
              {href}
            </Link>
              ))
            )}
      </Box>
    </Box>
  )
}
