import { Theme, alpha } from '@mui/material/styles'

export default function Card (theme: Theme) {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          position: 'relative' as 'relative',
          boxShadow: `0 0 2px 0 ${alpha(theme.palette.grey[500], 0.2)}, 0 12px 24px -4px ${alpha(theme.palette.grey[500], 0.12)}`,
          borderRadius: Number(theme.shape.borderRadius) * 2,
          zIndex: 0 // Fix Safari overflow: hidden with border radius
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h6' as 'h6' },
        subheaderTypographyProps: { variant: 'body2' as 'body2', marginTop: theme.spacing(0.5) }
      },
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 3, 0)
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3)
        }
      }
    }
  }
}
