import numeral from 'numeral'
import { format } from 'date-fns'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const uploadFile = (firebaseApp: any, bucket: string, fileName: string, file: File, callback: (url: string) => void) => {
  const callbackOverride = async () => {
    const url = await firebaseApp.storage(bucket).ref(fileName).getDownloadURL()
    callback(url)
  }

  firebaseApp.storage(bucket).ref(fileName).put(file).then(callbackOverride).catch(callbackOverride)
}

const arrayValueFromPath = (array: any[], path: string) => {
  const pathSplited: string[] = path.split('.')
  let newValue:any = array
  pathSplited.forEach((p: string) => {
    if (newValue && (newValue[p] || typeof newValue[p] === 'boolean')) { newValue = newValue[p] } else newValue = null
  })
  return newValue
}

const getDevice = () => {
  const theme = useTheme()

  return useMediaQuery(theme.breakpoints.up('lg')) ? 'desktop' : 'mobile'
}

const displayDate = (date: Date | string, dateFormat: 'ddmmyy' | 'mmddyy' | 'yymmdd') => {
  const mDate = new Date(date)
  if (dateFormat === 'yymmdd') {
    return format(mDate, 'yyyy/MM/dd')
  }
  if (dateFormat === 'mmddyy') {
    return format(mDate, 'MM/dd/yyyy')
  }
  if (dateFormat === 'ddmmyy') {
    return format(mDate, 'dd/MM/yyyy')
  }
  return format(mDate, 'dd/MM/yyyy')
  // return new Intl.DateTimeFormat('us-EN').format(new Date(date))
}

type Anonymous = Record<string | number, string>
type Order = 'asc' | 'desc'

const descendingComparator = (a: Anonymous, b: Anonymous, orderBy: string) => {
  if (arrayValueFromPath(b as any, orderBy) < arrayValueFromPath(a as any, orderBy)) {
    return -1
  }
  if (arrayValueFromPath(b as any, orderBy) > arrayValueFromPath(a as any, orderBy)) {
    return 1
  }
  return 0
}

const applySortFilter = (array: any[], order: Order, orderBy: string) => {
  const comparator: (a: any, b: any) => number = order === 'desc'
    ? (a: Anonymous, b: Anonymous) => descendingComparator(a, b, orderBy)
    : (a: Anonymous, b: Anonymous) => -descendingComparator(a, b, orderBy)

  return array.slice().sort((a, b) => {
    const order = comparator(a, b)
    if (order !== 0) return order
    return a[1] - b[1]
  })
}

const applySearchFilter = (array: any[], canBeSearch: any[], searchValue: string) => {
  return array
    .filter(row =>
      canBeSearch
        .map(searchIndex =>
          row[searchIndex] &&
          row[searchIndex]
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
        .includes(true))
}

const fNumber = (number: string | number, operator: boolean = true) => {
  return `${number > 0 && operator ? '+' : ''}${numeral(number).format()}`
  // return numeral(number).format()
}

const fShortenNumber = (number: string | number, operator: boolean = true) => {
  return `${number > 0 && operator ? '+' : ''}${numeral(number).format('0.00a').replace('.00', '')}`
  // return numeral(number).format('0.00a').replace('.00', '')
}

const fPercent = (number: number, operator: boolean = true): string => {
  return `${number > 0 && operator ? '+' : ''}${numeral(number / 100).format('0.0%')}`
}

const fCurrency = (number: string | number) => {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00')
}

const formatString = (string: string, format: 'capitalize' | 'firstLetter' | 'uppercase' | 'lowercase'): string => {
  if (format === 'firstLetter') { return string.charAt(0).toUpperCase() + string.slice(1) }
  if (format === 'capitalize') { return string.split(' ').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ') }
  if (format === 'uppercase') { return string.toUpperCase() }
  if (format === 'lowercase') { return string.toLowerCase() }
  return string
}

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error'

export { displayDate, getDevice, applySortFilter, applySearchFilter, arrayValueFromPath, uploadFile, fNumber, fShortenNumber, fPercent, fCurrency, formatString }
