import { useMemo } from 'react'
import merge from 'lodash/merge'
import { fNumber } from 'utils'

import { Card, CardHeader } from '@mui/material'
import { useTheme, styled } from '@mui/material/styles'

import ReactApexChart from 'react-apexcharts'
import BaseOptionChart from '../charts.data'

const CHART_HEIGHT = 392
const LEGEND_HEIGHT = 72

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important' as 'relative',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  }
}))

interface DonutProps {
  title: string
  subtitle?: string
  legends: string[]
  datas: number[]
}

const Donut = ({ title, subtitle, legends, datas }: DonutProps) => {
  const theme = useTheme()

  const chartOptions = useMemo(() => merge(
    BaseOptionChart(),
    {
      labels: legends,
      stroke: { colors: [theme.palette.background.paper] },
      legend: { floating: true, horizontalAlign: 'center' },
      tooltip: {
        fillSeriesColor: false,
        y: {
          formatter: (seriesName: string) => fNumber(seriesName),
          title: {
            formatter: (seriesName: string) => `${seriesName}`
          }
        }
      },
      plotOptions: {
        pie: {
          donut: {
            size: '90%',
            labels: {
              value: {
                formatter: (val: number | string) => fNumber(val)
              },
              total: {
                formatter: (w: { globals: { seriesTotals: number[] } }) => {
                  const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0)
                  return fNumber(sum)
                }
              }
            }
          }
        }
      }
    }
  ), [])

  return (
    <Card>
      <CardHeader title={title} subheader={subtitle} />
      <ChartWrapperStyle dir="ltr">
        <ReactApexChart type="donut" series={datas} options={chartOptions} height={280} />
      </ChartWrapperStyle>
    </Card>
  )
}

export default Donut
