import { styled, alpha } from '@mui/material/styles'
import { OutlinedInputProps, TextField } from '@mui/material'

interface Props extends OutlinedInputProps {
  stretchStart?: number;
}

const InputStyle = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'stretchStart'
})<Props>(({ stretchStart, theme }) => ({
  '& .MuiOutlinedInput-root': {
    transition: theme.transitions.create(['box-shadow', 'width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': {
      boxShadow: `0 12px 24px -4px ${alpha(theme.palette.primary.main, 0.16)}`
    },
    ...(stretchStart && {
      width: stretchStart,
      '&.Mui-focused': {
        boxShadow: `0 12px 24px -4px ${alpha(theme.palette.primary.main, 0.16)}`,
        [theme.breakpoints.up('sm')]: {
          width: stretchStart + 60
        }
      }
    })
  },
  input: {
    '&::placeholder': {
      textTransform: 'capitalize' as 'capitalize'
    }
  },
  '& fieldset': {
    borderWidth: '1px !important',
    borderColor: `${alpha('#919EAB', 0.32)} !important`
  }
}))

export default InputStyle
