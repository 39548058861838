import { Timeline, SquareFoot } from '@mui/icons-material'

import { NavConfigGenerator, NavItem, OverrideInfos } from './navbars.types'

const navConfig: NavConfigGenerator = (infos?: OverrideInfos) => [
  {
    id: 'challenger',
    subheader: 'menu.challenger',
    items: [
      {
        id: 'challenger-dashboard',
        title: 'menu.challenger.dashboard',
        path: '/challenger/dashboard',
        icon: <Timeline />
      },
      {
        id: 'challenger-measurements',
        title: 'menu.challenger.measurements',
        path: '/challenger/measurements',
        icon: <SquareFoot />
      }
    ].map((item: NavItem) => ({ ...item, info: infos && infos[item.id] }))
  }
]

export default navConfig
