import { useState, createContext, useContext } from 'react'

import { ApolloProvider, ApolloClient, createHttpLink, InMemoryCache, useMutation, useQuery, useLazyQuery } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import { InitialValues, ApiProviderProps } from './api.types'

const initialValues: InitialValues = {
  setAuthorizationToken: () => {},
  useQuery,
  useMutation,
  useLazyQuery
}

const ApiContext = createContext(initialValues)

const generateApolloHeader = (token?: string) => {
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_URL
  })

  const auth = setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        ...(token && { authorization: `Bearer ${token}` })
      }
    }
  })

  const authLink = auth.concat(httpLink)

  const client = new ApolloClient({
    link: authLink,
    cache: new InMemoryCache({})
  })

  return client
}

const ApiProvider = ({ children }: ApiProviderProps) => {
  const [authorizationToken, setAuthorizationToken] = useState<string | undefined>()

  return (
    <ApiContext.Provider value={{
      setAuthorizationToken,
      useMutation,
      useQuery,
      useLazyQuery
    }}>
      <ApolloProvider client={generateApolloHeader(authorizationToken)}>
        {children}
      </ApolloProvider>
    </ApiContext.Provider>
  )
}

const useApi = () => {
  return useContext(ApiContext)
}

export { ApiProvider, useApi }
