import { StrictMode, Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

import { LazyMotion, domMax } from 'framer-motion'

import './locales/i18n'

import reportWebVitals from './reportWebVitals'

import { ContextsProvider, LoadingScreen } from 'components'

const LazyApp = lazy(() => import('./App'))

ReactDOM.render(
  <StrictMode>
    <ContextsProvider>
      <HelmetProvider>
        <BrowserRouter>
          <LazyMotion strict features={domMax}>
            <Suspense fallback={<LoadingScreen />}>
              <div id='firebase-recaptcha-container' />
              <LazyApp />
            </Suspense>
          </LazyMotion>
        </BrowserRouter>
      </HelmetProvider>
    </ContextsProvider>
  </StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
