import merge from 'lodash/merge'

import { Card, CardHeader } from '@mui/material'
import { useTheme, styled } from '@mui/material/styles'

import ReactApexChart from 'react-apexcharts'

import BaseOptionChart from '../charts.data'

import { formatString } from 'utils'

const CHART_HEIGHT = 392
const LEGEND_HEIGHT = 72

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(2),
  '& .apexcharts-canvas svg': {
    height: CHART_HEIGHT
  },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important' as 'relative',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  }
}))

interface RadarDataProps {
  name: string
  data: number[]
}

interface RadarProps {
  title: string
  subtitle?: string
  legends: string[]
  datas: RadarDataProps[]
}

const Radar = ({ title, subtitle, legends, datas }: RadarProps) => {
  const theme = useTheme()

  const chartOptions = merge(
    BaseOptionChart(),
    {
      stroke: { width: 2 },
      fill: { opacity: 0.48 },
      legend: {
        floating: true,
        horizontalAlign: 'center' as 'center',
        position: 'bottom' as 'bottom',
        labels: {
          colors: theme.palette.text.secondary
        }
      },
      xaxis: {
        categories: legends.map(legend => formatString(legend, 'capitalize')),
        labels: {
          style: {
            colors: Array(legends.length).fill(theme.palette.text.secondary)
          }
        }
      }
    }
  )

  return (
    <Card>
      <CardHeader title={title} subheader={subtitle} />
      <ChartWrapperStyle dir="ltr">
        <ReactApexChart type="radar" series={datas} options={chartOptions} height={340} />
      </ChartWrapperStyle>
    </Card>
  )
}

export default Radar
