import { Dispatch, SetStateAction } from 'react'

import { ColumnProps } from '../../table.types'

import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material'

type Order = 'asc' | 'desc'

interface HeadProps {
  columns: ColumnProps[]
  order: Order
  orderBy: string
  setOrder: Dispatch<SetStateAction<Order>>
  setOrderBy: Dispatch<SetStateAction<string>>
  actions?: boolean
}

const Head = ({ columns, order, orderBy, setOrder, setOrderBy, actions }: HeadProps) => {
  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <TableHead>
      <TableRow>
        {columns.map((column: ColumnProps) => (
          <TableCell
            key={column.id}
            align={column.align || 'left'}
            sortDirection={column.canSort && orderBy === column.id ? order : false}
          >
            {column.canSort
              ? <TableSortLabel
                  hideSortIcon
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : 'asc'}
                  onClick={() => handleRequestSort(column.id)}
                >
                  {column.label}
                </TableSortLabel>
              : column.label}
          </TableCell>
        ))}
        {actions && <TableCell />}
      </TableRow>
    </TableHead>
  )
}

export default Head
