import { createContext, useContext, useState } from 'react'

import { Snackbar as SnackbarMui, Alert } from '@mui/material'
import { SnackbarOrigin } from '@mui/material/Snackbar'
import { AlertProps } from '@mui/material/Alert'

import { formatString } from 'utils'

import { InitialValues, SnackbarProviderProps, DisplaySnackbarProps } from './snackbar.types'

const initialValues: InitialValues = {
  displaySnackbar: () => {},
  forceSnackbarClosing: () => {}
}

const Snackbar = createContext(initialValues)

const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const [open, setOpen] = useState<boolean>(false)

  const handleClose = () => { setOpen(false) }

  const [message, setMessage] = useState<string>('default message')
  const [vertical, setVertical] = useState<SnackbarOrigin['vertical']>('top')
  const [horizontal, setHorizontal] = useState<SnackbarOrigin['horizontal']>('right')
  const [status, setStatus] = useState<AlertProps['severity']>('info')
  const [autoHideDuration, setAutoHideDuration] = useState<number>(6000)

  const displaySnackbar = ({ message, vertical, horizontal, status, autoHideDuration }: DisplaySnackbarProps) => {
    setMessage(message)
    setVertical(vertical || 'top')
    setHorizontal(horizontal || 'right')
    setStatus(status || 'info')
    setAutoHideDuration(autoHideDuration || 6000)
    setOpen(true)
  }

  return (
    <Snackbar.Provider value={{ displaySnackbar, forceSnackbarClosing: handleClose }}>
      {children}
      <SnackbarMui
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={autoHideDuration}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert elevation={6} variant="filled" onClose={handleClose} severity={status} sx={{ width: '100%', fontWeight: '500' }}>
          {formatString(message, 'firstLetter')}
        </Alert>
      </SnackbarMui>
    </Snackbar.Provider>
  )
}

const useSnackbar = () => {
  return useContext(Snackbar)
}

export { SnackbarProvider, useSnackbar }
