import { gql } from '@apollo/client'

const GET_USER = gql`
  query {
    user: getUser {
      id
      firebaseId
      fullName
      firstName
      lastName
      email
      goal
      height
      phone
      isCoached
      isCoached
      isCoach
      inZoneEuro
      countryCode
      isAdmin
      picture {
        id
        bucket
        fileName
        url
      }
      preferences {
        units {
          height
          measure
          weight
          date
          time
        }
        language
      }
      measurements {
        id
        composition {
          water
          visceralFat
          weight
          muscularMass
          bodyFat
        }
        measures {
          neck
          quadriceps {
            left
            right
          }
          shoulders
          calves {
            left
            right
          }
          chest
          forearms {
            left
            right
          }
          hip
          abs
          biceps {
            left
            right
          }
        }
        date
      }
    }
  }
`

export { GET_USER }
