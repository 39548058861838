import { useState, createContext, useContext, useMemo } from 'react'

import { CssBaseline } from '@mui/material'
import { createTheme, ThemeProvider, ThemeOptions, StyledEngineProvider } from '@mui/material/styles'
import { BrandProviderProps, InitialValues, Brand } from './brand.types'

import componentsOverride from './theme/overrides'
import typography from './theme/typography'
import breakpoints from './theme/breakpoints'

import { brand, appConfig } from 'configs'

const initialValues: InitialValues = {
  brand,
  setBrand: () => {},
  resetBrand: () => {}
}

const BrandContext = createContext(initialValues)

const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A'
}
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D'
}
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01'
}
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E'
}

const BrandProvider = ({ children }: BrandProviderProps) => {
  const [brand, setBrand] = useState<Brand>(initialValues.brand)

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: {
        type: 'light',
        primary: { main: brand.colors.primary, contrastText: brand.colors.textColor },
        secondary: brand.colors.secondary ? { main: brand.colors.secondary, contrastText: brand.colors.textColor } : undefined,
        info: { ...INFO, contrastText: '#fff' },
        success: { ...SUCCESS, contrastText: '#212B36' },
        warning: { ...WARNING, contrastText: '#212B36' },
        error: { ...ERROR, contrastText: '#fff' }
      },
      typography,
      breakpoints,
      shape: { borderRadius: 8 },
      direction: 'ltr'
    }),
    [brand.colors]
  )

  const theme = createTheme(themeOptions)
  theme.components = componentsOverride(theme)

  const resetBrand = () => {
    setBrand(initialValues.brand)
  }

  const setBrandOverride = appConfig.whiteBranding ? setBrand : () => {}

  return (
    <BrandContext.Provider value={{ brand, setBrand: setBrandOverride, resetBrand }}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </BrandContext.Provider>
  )
}

const useBrand = () => {
  return useContext(BrandContext)
}

export { BrandProvider, useBrand }
