import { useMemo } from 'react'

import merge from 'lodash/merge'
import { useTheme } from '@mui/material/styles'
import { Card, CardHeader, Stack, Box, Typography } from '@mui/material'

import ReactApexChart from 'react-apexcharts'
import BaseOptionChart from '../charts.data'

import { fNumber } from 'utils'

interface LegendLabelsProps {
  value: string
  total: string
}

interface LegendProps {
  value: number
  labels: LegendLabelsProps
  main?: boolean
}

const Legend = ({ labels, value, main }: LegendProps) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center" spacing={1}>
        <Box
          sx={{
            width: 16,
            height: 16,
            bgcolor: '#edeff1',
            borderRadius: 0.75,
            ...(main && {
              bgcolor: 'primary.main'
            })
          }}
        />
        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
          {labels.value}
        </Typography>
      </Stack>
      <Typography variant="subtitle1">{value} {labels.total}</Typography>
    </Stack>
  )
}

interface RadialBarCentralLegendsProps {
  value: string
  total: string
  rest: string
}

interface RadialBarCentralProps {
  title: string
  subtitle?: string
  value: number
  total: number
  legends: RadialBarCentralLegendsProps
  displayCenter?: 'VALUE' | 'TOTAL' | 'REST'
}

const RadialBarCentral = ({ title, subtitle, value, total, legends, displayCenter }: RadialBarCentralProps) => {
  const theme = useTheme()

  const chartOptions = useMemo(() => merge(
    BaseOptionChart(),
    {
      legend: { show: false },
      grid: {
        padding: { top: -32, bottom: -32 }
      },
      fill: {
        type: 'gradient',
        gradient: {
          colorStops: [
            [
              { offset: 0, color: theme.palette.primary.light },
              { offset: 100, color: theme.palette.primary.main }
            ]
          ]
        }
      },
      plotOptions: {
        radialBar: {
          hollow: { size: '64%' },
          dataLabels: {
            name: { offsetY: -16 },
            value: { offsetY: 8 },
            total: {
              label: displayCenter === 'REST' ? legends.rest : displayCenter === 'VALUE' ? legends.value : legends.total,
              formatter: () => fNumber(displayCenter === 'REST' ? (total - value) : displayCenter === 'VALUE' ? value : total)
            }
          }
        }
      }
    }
  ), [])

  return (
    <Card>
      <CardHeader title={title} subheader={subtitle} sx={{ mb: 8 }} />
      <ReactApexChart type="radialBar" series={[value * 100 / total]} options={chartOptions} height={310} />

      <Stack spacing={2} sx={{ p: 5 }}>
        <Legend labels={{
          value: legends.value,
          total: legends.total
        }} value={value} main={true} />
        <Legend labels={{
          value: legends.rest,
          total: legends.total
        }} value={total - value} />
      </Stack>
    </Card>
  )
}

export default RadialBarCentral
