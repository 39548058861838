import { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { TablePagination } from '@mui/material'

import { formatString } from 'utils'

interface PaginationProps {
  count: number
  rowsPerPage: number
  page: number
  setPage: Dispatch<SetStateAction<number>>
  setRowsPerPage: Dispatch<SetStateAction<number>>
}

const Pagination = ({ count, rowsPerPage, page, setPage, setRowsPerPage }:PaginationProps) => {
  const { t } = useTranslation()
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25, 50]}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={(_e, page) => setPage(page)}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelRowsPerPage={formatString(t('global.table.rowsperpage'), 'firstLetter')}
      labelDisplayedRows={({ from, to, count }) => t('global.table.of', { from, to, count })}
    />
  )
}

export default Pagination
