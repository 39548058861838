import { useMemo } from 'react'

import { alpha, useTheme, styled } from '@mui/material/styles'
import { Box, Card, Typography, Stack } from '@mui/material'
import { TrendingUp as TrendingUpIcon, TrendingDown as TrendingDownIcon } from '@mui/icons-material'

import { fNumber, fPercent } from 'utils'

import ReactApexChart from 'react-apexcharts'

const IconWrapperStyle = styled('div')(({ theme }) => ({
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center'
}))

type QuickEvolveProps = {
  title: string
  value: number
  datas: number[]
  evolveLegend?: string
  dataType?: 'bar' | 'line'
  needToGoDown?: boolean
}

const QuickEvolve = ({ title, value, datas, dataType = 'bar', evolveLegend, needToGoDown }: QuickEvolveProps) => {
  const theme = useTheme()

  const percent = useMemo(() => ((100 - datas[datas.length - 1] * 100 / datas[datas.length - 2]) * -1), [])

  const chartOptions = {
    colors: [(percent >= 0 && !needToGoDown) || (percent < 0 && needToGoDown) ? theme.palette.success.main : theme.palette.error.main],
    chart: { sparkline: { enabled: true } },
    plotOptions: { bar: { columnWidth: '68%', borderRadius: 2 } },
    stroke: { width: 2 },
    tooltip: {
      x: { show: false },
      y: {
        formatter: (seriesName: number | string) => fNumber(seriesName),
        title: {
          formatter: () => ''
        }
      },
      marker: { show: false }
    }
  }

  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2">{title}</Typography>

        <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2, mb: 1 }}>
          <IconWrapperStyle
            sx={{
              ...((percent >= 0 && !needToGoDown) || (percent < 0 && needToGoDown)
                ? {
                    color: 'success.main',
                    bgcolor: alpha(theme.palette.success.main, 0.16)
                  }
                : {
                    color: 'error.main',
                    bgcolor: alpha(theme.palette.error.main, 0.16)
                  })
            }}
          >
            {percent < 0 ? <TrendingDownIcon fontSize='small' /> : <TrendingUpIcon fontSize='small' />}
          </IconWrapperStyle>
          <Typography component="span" variant="subtitle2">
            {fPercent(percent)}
          </Typography>
          {evolveLegend && <Typography variant="body2" component="span" noWrap sx={{ color: 'text.secondary' }}>
            {evolveLegend}
          </Typography>}
        </Stack>

        <Typography variant="h3">{fNumber(value)}</Typography>
      </Box>

      <ReactApexChart
        type={dataType}
        series={[{ data: datas }]}
        options={chartOptions}
        width={60}
        height={36}
      />
    </Card>
  )
}

export default QuickEvolve
