import { ReactElement } from 'react'
import { styled } from '@mui/material/styles'
import { Card, Typography, Box } from '@mui/material'
import { fShortenNumber, ColorSchema } from 'utils'

const RootStyle = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 2, 2, 3)
}))

type DataIlluProps = {
  title: string
  total: number
  icon: ReactElement
  illuColor?: ColorSchema
}

const DataIllu = ({ title, total, icon, illuColor = 'primary' }: DataIlluProps) => {
  return (
    <RootStyle>
      <div>
        <Typography variant="h3">{fShortenNumber(total)}</Typography>
        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
          {title}
        </Typography>
      </div>
      <Box
        sx={{
          width: 120,
          height: 120,
          lineHeight: 0,
          borderRadius: '50%',
          bgcolor: 'background.neutral',
          color: (theme) => theme.palette[illuColor].main
        }}
      >
        {icon}
      </Box>
    </RootStyle>
  )
}

export default DataIllu
