import { MouseEventHandler } from 'react'

import { alpha, styled } from '@mui/material/styles'
import { Typography, Box, BoxProps, IconButton } from '@mui/material'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'

const RootStyle = styled(Box)(({ theme }) => ({
  zIndex: 9,
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  color: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.grey[900], 0.48)
}))

const ArrowStyle = styled(IconButton)(({ theme }) => ({
  padding: 6,
  opacity: 0.48,
  color: theme.palette.common.white,
  '&:hover': { opacity: 1 }
}))

interface Props extends BoxProps {
  index: number
  total: number
  customIcon?: any
  onNext?: MouseEventHandler
  onPrevious?: MouseEventHandler
}

const ArrowsIndex = ({
  index,
  total,
  onNext,
  onPrevious,
  customIcon,
  ...other
}: Props) => {
  return <RootStyle {...other}>
    <ArrowStyle size="small" onClick={onPrevious}>
      <ArrowBackIos />
    </ArrowStyle>

    <Typography variant="subtitle2">
      {index + 1}/{total}
    </Typography>

    <ArrowStyle size="small" onClick={onNext}>
      <ArrowForwardIos />
    </ArrowStyle>
  </RootStyle>
}

export default ArrowsIndex
