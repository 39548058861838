import { createContext, useContext, useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
// import i18n from 'i18next'

import { InitialValues, AuthProviderProps, LoginDataProps, OtpConfirmDataProps, User } from './auth.types'

import { useApi } from '../api/Api'
import { useFirebase } from '../firebase/Firebase'

import { GET_USER } from './auth.apollo'

const initialValues: InitialValues = {
  loading: true,
  login: (data: any) => {},
  otpConfirm: (data: any) => {},
  logout: () => {},
  user: undefined,
  setUser: () => {},
  loginPhone: undefined,
  isConnected: false
}

const AuthContext = createContext(initialValues)

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [user, setUser] = useState<User | undefined>()
  const [loginPhone, setLoginPhone] = useState<string | undefined>()
  const [firebaseOtpConfirm, setFirebaseOtpConfirm] = useState<any>(null)
  const { firebase } = useFirebase()
  const { setAuthorizationToken, useLazyQuery } = useApi()
  const { i18n } = useTranslation()

  const [getProfile, { data }] = useLazyQuery(GET_USER)

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (firebaseUser: any) => {
      if (firebaseUser) {
        const token = await firebaseUser.getIdToken()
        setAuthorizationToken(token)
        getProfile()
      } else {
        setAuthorizationToken(undefined)
        setUser(undefined)
        setLoading(false)
      }
    })
  }, [])

  useEffect(() => {
    if (data) {
      setUser({
        ...data.user,
        role: data.user.isAdmin ? 'ADMIN' : data.user.isCoach ? 'COACH' : 'CHALLENGER'
        // role: 'COACH'
      })
      if (data.user.preferences?.language) { i18n.changeLanguage(data.user.preferences.language.toLowerCase()) }
      setLoading(false)
    }
  }, [data])

  const login = async ({ phone, callback }: LoginDataProps) => {
    if (user) return

    const captcha =
      new firebase.auth.RecaptchaVerifier('firebase-recaptcha-container', {
        size: 'invisible'
      })

    firebase.auth()
      .signInWithPhoneNumber(phone, captcha)
      .then((confirmationResult: any) => {
        setLoginPhone(phone)
        setFirebaseOtpConfirm(confirmationResult)
        callback()
      })
      .catch((err: Error) => {
        console.log(err)
        setLoginPhone(undefined)
        window.location.reload()
        callback(err)
      })
  }

  const otpConfirm = async ({ otp, callback }: OtpConfirmDataProps) => {
    if (user || !firebaseOtpConfirm || otp.length !== 6) return

    firebaseOtpConfirm.confirm(otp).then(async (_result: any) => {
      callback()
    }).catch((err: Error) => {
      callback(err)
    })
  }

  const logout = () => {
    firebase.auth().signOut()
    setUser(undefined)
    setLoginPhone(undefined)
    setFirebaseOtpConfirm(undefined)
  }

  return (
    <AuthContext.Provider value={{
      loading,
      login,
      otpConfirm,
      logout,
      user,
      setUser,
      loginPhone,
      isConnected: Boolean(user)
    }}>
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => {
  return useContext(AuthContext)
}

export { AuthProvider, useAuth }
