import { Leaderboard, People } from '@mui/icons-material'

import { NavConfigGenerator, NavItem, OverrideInfos } from './navbars.types'

const navConfig: NavConfigGenerator = (infos?: OverrideInfos) => [
  {
    id: 'coach',
    subheader: 'menu.coach',
    items: [
      {
        id: 'coach-dashboard',
        title: 'menu.coach.dashboard',
        path: '/coach/dashboard',
        icon: <Leaderboard />
      },
      {
        id: 'coach-challengers',
        title: 'menu.coach.challengers',
        path: '/coach/challengers',
        icon: <People />
      }
    ].map((item: NavItem) => ({ ...item, info: infos && infos[item.id] }))
  }
]

export default navConfig
