import { Dining as DiningIcon, Settings as SettingsIcon } from '@mui/icons-material'

import { NavConfigGenerator, NavItem, OverrideInfos } from './navbars.types'

const navConfig: NavConfigGenerator = (infos?: OverrideInfos) => [
  {
    id: 'app',
    subheader: 'menu.app',
    items: [
      {
        id: 'app-recipes',
        title: 'menu.app.recipes',
        path: '/app/recipes',
        icon: <DiningIcon />
      },
      {
        id: 'app-settings',
        title: 'menu.app.settings',
        path: '/app/settings',
        icon: <SettingsIcon />
      }
    ].map((item: NavItem) => ({ ...item, info: infos && infos[item.id] }))
  }
]

export default navConfig
