import { GroupNavItem, OverrideInfos, NavConfigs, NavConfigGenerator } from './navbars.types'

import navConfigChallenger from './navbarChallenger'
import navConfigCoach from './navbarCoach'
import navConfigApp from './navbarApp'
import navConfigAdmin from './navbarAdmin'

const navConfigElements: NavConfigs = {
  CHALLENGER: [navConfigChallenger, navConfigApp],
  COACH: [navConfigCoach, navConfigChallenger, navConfigApp],
  ADMIN: [navConfigAdmin]
}

const navConfigs: (role: string, infos?: OverrideInfos) => GroupNavItem[] | undefined = (role: string, infos?: OverrideInfos) => navConfigElements[role] && navConfigElements[role].flatMap((nav: NavConfigGenerator) => nav(infos))

export default navConfigs
