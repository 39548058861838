import { BarChart, ManageAccounts, People, GroupWork, Business } from '@mui/icons-material'

import { NavConfigGenerator, NavItem, OverrideInfos } from './navbars.types'

const navConfig: NavConfigGenerator = (infos?: OverrideInfos) => [
  {
    id: 'general',
    subheader: 'general',
    items: [
      {
        id: 'dahbaord',
        title: 'Dashboard',
        path: '/app/dashboard',
        icon: <BarChart />
      },
      {
        id: 'users',
        title: 'users',
        path: '/app/users',
        icon: <People />
      },
      {
        id: 'teams',
        title: 'teams',
        path: '/app/teams',
        icon: <GroupWork />
      },
      {
        id: 'settings',
        title: 'settings',
        path: '/app/settings',
        icon: <ManageAccounts />
      },
      {
        id: 'organization',
        title: 'organization',
        path: '/app/organization/settings',
        icon: <Business />
      }
    ].map((item: NavItem) => ({ ...item, info: infos && infos[item.id] }))
  }
]

export default navConfig
