import { Theme } from '@mui/material/styles'

import '@mui/lab/themeAugmentation'

export default function Link (theme: Theme) {
  return {
    MuiLink: {
      defaultProps: {
        underline: 'hover' as 'hover'
      }
    }
  }
}
