import { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { Toolbar, InputAdornment } from '@mui/material'
import { Search } from '@mui/icons-material'

import { InputStyle } from 'components'

interface MToolbarProps {
  search: string
  setSearch: Dispatch<SetStateAction<string>>
}

const MToolbar = ({ search, setSearch }: MToolbarProps) => {
  const { t } = useTranslation()

  return (
    <Toolbar sx={{
      height: 96,
      display: 'flex',
      justifyContent: 'space-between'
    }}>
      <InputStyle
        stretchStart={240}
        value={search}
        onChange={(event: ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
        placeholder={t('global.input.searchetc')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search
                sx={{ color: 'text.disabled', fontSize: 20 }}
              />
            </InputAdornment>
          )
        }}
      />
    </Toolbar>
  )
}

export default MToolbar
